import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";


function MidBlockNews() {
  var [tagsItems, updatetage] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState("true");

  useEffect(() => {
    setIsLoading("true")
    axios
      .get("https://playstationcommunity.hu/wp-json/kingweb/v1/hirek?page=1&limit=6&tag=kiemelt")
      .then((res) => {
        updatetage(res.data)
        setIsLoading("false")
      })
  }, [])
  if(isLoading === "true") {
    return (
        <div></div>
    )
  } else {
    return (
        <div className="newtagdiv">
            {tagsItems.map((val, key) => {
                return (

                        <div className="tagDiv">
                        <Link to={{ pathname: '/hirek/komment/' + val.slug }}>
                        <div id="containertag">
                        <div id="navitag"><img className="imgtag" src={val.jetpack_featured_media_url} style={{width: "100%", opacity: "1"}}></img></div>
                        <div id="infoitag">
                        <p className="tagTitle">{val.title.rendered}</p>
                        </div>
                        </div>
                        </Link>
                        </div>
                )
                })}
        </div>
        
      )
  }
    
}

export default MidBlockNews;